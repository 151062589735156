import Main from "./Main";
import ApiKeyContextProvider from "./context/apiKeyContext";
import ChatContextProvider from "./context/chatContext";
import Snowfall from "react-snowfall";
import ReactOpenApiRenderer from "@tx-dts/react-openapi-renderer";
import "@tx-dts/react-openapi-renderer/dist/index.css";
import svexaApiOpenApiSpec from "./fuelCalculatorDocumentation.json";

function App() {
  const date = new Date();
  const month_as_int = date.getMonth();
  const is_winter = [11, 0, 1].includes(month_as_int);

  if (window.location.href.includes("/fuel-calculator-redoc")) {
    return <ReactOpenApiRenderer specification={svexaApiOpenApiSpec} />;
  }
  return (
    <ApiKeyContextProvider>
      <ChatContextProvider>
        <Main />
        {is_winter && <Snowfall color="#C0F6FB" snowflakeCount={60} />}
      </ChatContextProvider>
    </ApiKeyContextProvider>
  );
}

export default App;
